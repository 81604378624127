/* src/pages/AboutUs.css */
.content {
    padding: 0 0px;
  }
  
  .content-container {
    background: #fff;
    padding: 24px;
    min-height: 280px;
  }
  .content-container-contactus {
    background: #fff;
    padding: 24px;
  }
  .clickable-card{
    cursor: pointer;
    color: #4caf50 !important;
    text-align: center;
  }
  .clickable-card-icon{
    color: #4caf50;
    display: block;
    font-size: 40px !important;
  }
  .clickable-card-text{
    
    color: #4caf50;
  }
  .matter-container{
    margin-bottom: 20px;
  }
  .matter-container-itemlist-title{
    font-weight: bold;
  }
  .matter-container-itemlist-card{
    margin-bottom: 20px;
  }
  /* styles.css */
  .custom-card-header .ant-card-head {
    background-color: #4caf50 !important;
    color: #ffffff !important;
    text-align: center !important;
  }

  .content-minititle-withbg{
    background-color: #4caf50 !important;
    color: #ffffff !important;
    padding: 10px;
    font-weight: bold;

  }
  .content-sub-minititle-withoutbg{
    padding: 10px;
    font-weight: bold;
  }
  .content-sub-minititle-withoutbg-different-textcolor{
    font-weight: bold;
    color: #4caf50 !important;
  }
  .semitext{
    font-size: 20px;
    color: white !important;
    font-style: italic;
    text-align: center;
  }
  .semitext-background{
    background-color: #4caf50 !important;
    width: 100%;
    text-align: center;
    padding-top: 1%;
    padding-bottom: 0%;
    margin-bottom: 10px;
  }
  .testimonials-container {
    height: 300px; /* Adjust the height as needed */
    overflow-y: scroll;
    padding-right: 15px; /* To prevent content from hiding behind the scroll bar */
  }
  
  .testimonials-row {
    display: flex;
    flex-direction: column;
  }
  
  .testimonials-container::-webkit-scrollbar {
    width: 10px;
  }
  
  .testimonials-container::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  
  .testimonials-container::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }
  

.banner-button {
  background-color: #4caf50;
  border-color: #4caf50;
}

.banner-button:hover {
  background-color: #265728 !important; /* Reduced orange tone */
  border-color: #265728;
}

.banner-button:active {
  background-color: #4caf50;
  border-color: #4caf50;
}
/* Add this CSS to your stylesheet */
.desktop-only {
  display: none;
}

.mobile-only {
  display: block;
}

@media (min-width: 768px) {
  .desktop-only {
    display: flex;
  }
  .mobile-only {
    display: none;
  }
}

/* Optional: Ensure Drawer Menu styling */
.ant-drawer-body {
  padding: 0;
}
/* Add this CSS to your stylesheet */
.mobile-only {
  display: block;
}

@media (min-width: 768px) {
  .mobile-only {
    display: none;
  }
}
