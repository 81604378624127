.banner-content-title{
    font-size: 60px;
    text-align: center !important;
}
.banner-content{
    text-align: center;
    padding-top: 20vh;
    padding-left: 6vb;
    color: white;

}
.menu-button{
    color: white !important;
}
.close-drawer-button{
    color: black !important;
}

.mainpage-content{
    padding: 10px;
}
.banner-content-sub-title{
    font-size: 30px;
}
.banner-content-second-subtitle{
    font-size: 20px;
}
.banner-button{
    background-color: brown;
}
.overlay {
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Black transparent overlay */
    z-index: .2;
  }
@media (max-width: 768px) {
    .component-title{
        font-size: 30px !important;
        text-align: center;
    }
    .matter-container {
      padding: 10px;
    }
    .banner-col-area{
        padding: 0px !important;
    }
    .banner-row-area{
        margin-right: 2vb !important;
    }
    .overlay {
        position: absolute;
        top: 7%;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Black transparent overlay */
        z-index: .2;
      }
   
}
@media (max-width: 700px) {
    .component-title{
        font-size: 30px !important;
        text-align: center;
    }
    .matter-container {
      padding: 10px;
    }
    .banner-col-area{
        padding: 0px !important;
    }
    .banner-row-area{
        margin-right: 2vb !important;
    }
    .overlay {
        position: absolute;
        top: 9.2%;
        left: 0;
        width: 100%;
        height: 90%;
        background-color: rgba(0, 0, 0, 0.5); /* Black transparent overlay */
        z-index: .2;
      }
      .banner-content-title{
        font-size: 40px;
        text-align: center !important;
    }
   
}